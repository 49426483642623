.w3-2018-red-pear
{color:#fff;background-color:#7F4145}
.w3-2018-valiant-poppy
{color:#fff;background-color:#BD3D3A}
.w3-2018-nebulas-blue
{color:#fff;background-color:#3F69AA}
.w3-2018-ceylon-yellow
{color:#000;background-color:#D5AE41}
.w3-2018-martini-olive
{color:#fff;background-color:#766F57}
.w3-2018-russet-orange
{color:#fff;background-color:#E47A2E}
.w3-2018-crocus-petal
{color:#000;background-color:#BE9EC9}
.w3-2018-limelight
{color:#000;background-color:#F1EA7F}
.w3-2018-quetzal-green
{color:#fff;background-color:#006E6D}
.w3-2018-sargasso-sea
{color:#fff;background-color:#485167}
.w3-2018-tofu
{color:#000;background-color:#EAE6DA}
.w3-2018-almond-buff
{color:#000;background-color:#D1B894}
.w3-2018-quiet-gray,.w3-2018-quiet-grey
{color:#000;background-color:#BCBCBE}
.w3-2018-meerkat
{color:#000;background-color:#95dee3}
.w3-2018-meadowlark
{color:#000;background-color:#ECDB54}
.w3-2018-cherry-tomato
{color:#fff;background-color:#E94B3C}
.w3-2018-little-boy-blue
{color:#000;background-color:#6F9FD8}
.w3-2018-chili-oil
{color:#fff;background-color:#944743}
.w3-2018-pink-lavender
{color:#000;background-color:#DBB1CD}
.w3-2018-blooming-dahlia
{color:#000;background-color:#EC9787}
.w3-2018-arcadia
{color:#fff;background-color:#00A591}
.w3-2018-emperador
{color:#fff;background-color:#6C4F3D}
.w3-2018-ultra-violet
{color:#fff;background-color:#6B5B95}
.w3-2018-almost-mauve
{color:#000;background-color:#EADEDB}
.w3-2018-spring-crocus
{color:#fff;background-color:#BC70A4}
.w3-2018-lime-punch
{color:#000;background-color:#BFD641}
.w3-2018-sailor-blue
{color:#fff;background-color:#2E4A62}
.w3-2018-harbor-mist
{color:#000;background-color:#B4B7BA}
.w3-2018-warm-sand
{color:#000;background-color:#C0AB8E}
.w3-2018-coconut-milk
{color:#000;background-color:#F0EDE5}

