.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  
  color: white;
  padding: 1vh;
}
.App-nav {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 1vmin);
  color: white;
  padding: 1vh;
}
.App-body{
  background-color: #ffffff;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  text-align: left;
  color: black;
  padding: 1vh;
}
.App-footer{
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 2vh;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.grid-container {
      display: grid;
      grid-gap: 5px;
      grid-auto-flow: row;
      justify-items: center;
    }
    @media (max-width: 767px) { 
      .grid-container { 
        grid-auto-flow: column;
      }
    }
